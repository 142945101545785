import GraphQL from "utils/graphql"

export const DRAFT_MODE_SECRET = "oIkrilG6lAY5+w=="

const gqlEndpoint = process.env.REACT_APP_GRAPHQL_EVENTS ?? "http://localhost:3999/api/graph"

type JsonDumpInput = {
  id?: string
  type: string
  data: string
}
type SaveJsonDumpResponse = {
  data: {
    admin: {
      jsonDump: {
        save: string
      }
    }
  }
}
export const saveJsonDump = (jsonDumpInput: JsonDumpInput, mode: "USER" | "SYSTEM" = "USER") => {
  return GraphQL(
    `mutation {
      admin {
        jsonDump{
          save(
              jsonDump: {
                ${jsonDumpInput.id ? `id: ${JSON.stringify(jsonDumpInput.id)},` : ""}
                type: ${JSON.stringify(jsonDumpInput.type)},
                data: ${JSON.stringify(jsonDumpInput.data)}
              },  
              mode: ${JSON.stringify(mode)}
            )
          }
        }
      }
    `,
    undefined,
    undefined,
    undefined,
    undefined,
    gqlEndpoint
  ).then((response: SaveJsonDumpResponse) => response.data.admin.jsonDump.save)
}

type FetchJsonDumpResponse = {
  data: {
    admin: {
      jsonDump: {
        get: null | {
          data: string
          id: string
          type: string
          userId: string
        }
      }
    }
  }
}

export const fetchJsonDump = (id: string) => {
  return GraphQL(
    `query {
      admin {
        jsonDump{
            get(id: ${JSON.stringify(id)}) {
              id
              userId
              type
              data
            }
          }
        }
      }
    `,
    undefined,
    undefined,
    undefined,
    undefined,
    gqlEndpoint
  ).then((response: FetchJsonDumpResponse) =>
    // NULL is a valid response when there is no data associated with a given ID
    response.data.admin.jsonDump.get === null ? null : response.data.admin.jsonDump.get.data
  )
}
