import { useContext, useRef, useState } from "react"

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material"
import { useGridApiContext, type GridRenderEditCellParams } from "@mui/x-data-grid"
import { Ljl2025ResultsContext, type LJLMatchResult } from "contexts/ljl-2025-results"
import { useTranslation } from "react-i18next"

interface Props extends GridRenderEditCellParams<LJLMatchResult | null> {
  teamOptions: Array<{ value: string; label: string }>
}

export const CustomMatchResultsEdit = (props: Props) => {
  const { tab } = useContext(Ljl2025ResultsContext)
  const { id, value, field } = props
  const [newValue, setNewValue] = useState(
    value
      ? { ...value }
      : ({ opposingTeamId: props.teamOptions[0].value, victory: true, points: null } as LJLMatchResult)
  )
  const apiRef = useGridApiContext()
  const ref = useRef()
  const { t } = useTranslation()

  const handleSave = async () => {
    await apiRef.current.setEditCellValue({ id, field, value: newValue })
    const validCommit = await apiRef.current.commitCellChange({ id, field })
    if (!validCommit) {
      console.error("Committing error")
    }
    apiRef.current.setCellMode(id, field, "view")
  }

  const handleCancel = () => {
    apiRef.current.setCellMode(id, field, "view")
  }

  const handleDelete = async () => {
    await apiRef.current.setEditCellValue({ id, field, value: null })
    const validCommit = await apiRef.current.commitCellChange({ id, field })
    if (!validCommit) {
      console.error("Committing error")
    }
    apiRef.current.setCellMode(id, field, "view")
  }

  return (
    <Dialog
      open
      onClose={handleCancel}
    >
      <DialogContent dividers>
        <Stack gap={2}>
          <Autocomplete
            sx={{ minWidth: "375px" }}
            ref={ref}
            value={props.teamOptions.find((team) => team.value === newValue.opposingTeamId)}
            options={props.teamOptions}
            disableClearable
            onChange={(_event, newOpposingTeam) => {
              setNewValue((prevNewValue) => ({ ...prevNewValue, opposingTeamId: newOpposingTeam.value }))
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("taiyoro:ljl.victory.opposingTeam")}
              />
            )}
          />
          <FormControl>
            <FormLabel id="custom-match-results-win-loss">{t("taiyoro:ljl.victory.label")}</FormLabel>
            <RadioGroup
              aria-labelledby="custom-match-results-win-loss"
              name="radio-buttons-group"
              value={newValue.victory ? "win" : "loss"}
              onChange={(_event, victoryStringValue) => {
                setNewValue((prevNewValue) => ({ ...prevNewValue, victory: victoryStringValue === "win" }))
              }}
              onClick={(event) => event.stopPropagation()} // (Roy) Important to prevent propagation to the dialog (triggers close)
            >
              <FormControlLabel
                value="win"
                control={<Radio />}
                label={t("taiyoro:ljl.victory.win")}
              />
              <FormControlLabel
                value="loss"
                control={<Radio />}
                label={t("taiyoro:ljl.victory.loss")}
              />
            </RadioGroup>
          </FormControl>
          {tab === "ignite" && (
            <TextField
              value={newValue.points}
              label={t("taiyoro:ljl.victory.points")}
              onChange={(event) => {
                const value = event.target.value === "" ? null : event.target.value
                setNewValue((prevNewValue) => ({
                  ...prevNewValue,
                  points: value,
                }))
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{t("common:actions.cancel")}</Button>
        <Button onClick={() => void handleDelete()}>{t("common:actions.delete")}</Button>
        <Button onClick={() => void handleSave()}>{t("common:actions.save")}</Button>
      </DialogActions>
    </Dialog>
  )
}
