import { Stack } from "@mui/material"
import { ControlBar } from "components/Taiyoro/LJL/2025/ControlBar"
import { DataProvider } from "components/Taiyoro/LJL/2025/DataProvider"
import { EventResultContents } from "components/Taiyoro/LJL/2025/EventResultContents"

export const LJL2025ResultsEntryPage = () => {
  return (
    <DataProvider>
      <Stack gap={2}>
        <ControlBar />
        <EventResultContents />
      </Stack>
    </DataProvider>
  )
}
