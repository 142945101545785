import { faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, CardMedia, CardContent, Typography, CardActions, Button } from "@mui/material"
import MediaUpload from "components/Taiyoro/MediaUpload"
import { useTranslation } from "react-i18next"

interface Props {
  titleText: string
  image: string | null
  onChange: (newValue: string | null) => void
}

export const ImageUploadCard = ({ titleText, image, onChange }: Props) => {
  const { t } = useTranslation("taiyoro")

  const handleImageChange = (url: string) => {
    onChange(url === "" ? null : url)
  }

  return (
    <Card sx={{ maxWidth: 345, width: "100%" }}>
      <CardMedia
        component="img"
        sx={{ aspectRatio: "16/9", width: "100%" }}
        image={image ?? undefined}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
        >
          {titleText}
        </Typography>
      </CardContent>
      <CardActions>
        {image && (
          <Button
            variant="contained"
            startIcon={
              <FontAwesomeIcon
                icon={faTrash}
                size="1x"
              />
            }
            onClick={() => handleImageChange("")}
          >
            {t("ljl.changeImage")}
          </Button>
        )}
        {!image && (
          <MediaUpload
            key="ljl-image"
            url={image ?? ""}
            mediaType="ljl"
            onChange={handleImageChange}
            editable
            buttonText={t("ljl.uploadImage")}
            aspect={16 / 9}
          />
        )}
      </CardActions>
    </Card>
  )
}
