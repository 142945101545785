import { useContext } from "react"

import { Stack, Avatar, Tooltip, Typography } from "@mui/material"
import { type GridRenderEditCellParams, type GridRenderCellParams } from "@mui/x-data-grid"
import { Ljl2025ResultsContext, type LJLMatchResult } from "contexts/ljl-2025-results"

import { CustomMatchResultsEdit } from "./custom-match-results-edit"

export const useOpposingTeamCell = () => {
  const { tab, teams, forgeTeams, stormTeams, igniteTeams } = useContext(Ljl2025ResultsContext)

  const getTeamOptions = () => {
    switch (tab) {
      case "forge":
        return forgeTeams
      case "storm":
        return stormTeams
      case "ignite":
        return igniteTeams
      default:
        return teams
    }
  }

  const renderEditCell = (params: GridRenderEditCellParams) => (
    <CustomMatchResultsEdit
      {...params}
      teamOptions={getTeamOptions()}
    />
  )

  const renderCell = (params: GridRenderCellParams<LJLMatchResult | null>) => {
    if (params.value === null) return <></>
    const opposingTeamLabel = teams.find((team) => team.value === params.value!.opposingTeamId)?.label
    const opposingTeamObject = teams.find((team) => team.id === params.value!.opposingTeamId)
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: params.value!.victory ? "green" : "red",
        }}
        alignItems="center"
        justifyContent="center"
        padding={1}
      >
        <Stack
          px={1}
          direction="row"
          alignItems="center"
          gap={1}
        >
          <Tooltip title={opposingTeamLabel}>
            <Avatar
              alt={opposingTeamLabel}
              src={opposingTeamObject?.secondaryImage}
            />
          </Tooltip>
          {params.value!.points !== null && <Typography>{params.value!.points}</Typography>}
        </Stack>
      </Stack>
    )
  }

  return { renderCell, renderEditCell }
}
