import { useContext } from "react"

import { type GridValidRowModel, type GridCellEditCommitParams, type GridColumns } from "@mui/x-data-grid"
import { type LJL2025Results, Ljl2025ResultsContext } from "contexts/ljl-2025-results"
import { useTranslation } from "react-i18next"

import { ReorderableDataGrid } from "./reorderable-data-grid"

export const ChampionshipPoints = () => {
  const { loading, teams, forgeTeams, stormTeams, igniteTeams, modifiedResults, setModifiedResults } =
    useContext(Ljl2025ResultsContext)

  const { t } = useTranslation()

  const columns: GridColumns<GridValidRowModel> = [
    {
      flex: 1,
      field: "rank",
      sortable: false,
      headerName: t("taiyoro:ljl.tableHeaders.rank"),
      editable: true,
    },
    {
      flex: 1,
      field: "teamId",
      sortable: false,
      headerName: t("taiyoro:ljl.tableHeaders.team"),
      editable: true,
      type: "singleSelect",
      valueOptions: [...forgeTeams, ...stormTeams, ...igniteTeams],
      valueFormatter: ({ value }) => teams.find((teamValue) => teamValue.value === value)?.label,
    },
    {
      flex: 1,
      field: "forge",
      sortable: false,
      headerName: "FORGE",
      editable: true,
    },
    {
      flex: 1,
      field: "storm",
      sortable: false,
      headerName: "STORM",
      editable: true,
    },
    {
      flex: 1,
      field: "ignite",
      sortable: false,
      headerName: "IGNITE",
      editable: true,
    },
    {
      flex: 1,
      field: "total",
      sortable: false,
      headerName: t("taiyoro:ljl.tableHeaders.total"),
      editable: true,
    },
  ]

  const onCellEditCommit = (params: GridCellEditCommitParams) => {
    setModifiedResults((previousModifiedResults) => {
      const updatedModifiedResults = { ...previousModifiedResults }
      updatedModifiedResults["championship-points"].find((entry) => entry.id === params.id)![
        params.field as keyof LJL2025Results["championship-points"][0]
      ] = params.value as string
      return updatedModifiedResults
    })
  }

  return (
    <ReorderableDataGrid
      rows={modifiedResults["championship-points"]}
      columns={columns}
      disableColumnSelector
      disableColumnMenu
      disableVirtualization
      autoHeight={true}
      getRowHeight={() => "auto"}
      density="comfortable"
      loading={loading}
      rowCount={modifiedResults["championship-points"].length}
      onCellEditCommit={onCellEditCommit}
      hideFooter
      onTableDataPositionalChange={(modifiedRows: LJL2025Results["championship-points"]) => {
        setModifiedResults((previousModifiedResults) => {
          const updatedModifiedResults = { ...previousModifiedResults }
          updatedModifiedResults["championship-points"] = [...modifiedRows]
          return { ...updatedModifiedResults }
        })
      }}
    />
  )
}
