import { useContext } from "react"

import { Ljl2025ResultsContext } from "contexts/ljl-2025-results"

import { ImageUploadCard } from "../ImageUploadCard"

export const FinalsResults = () => {
  const { modifiedResults, setModifiedResults } = useContext(Ljl2025ResultsContext)

  const handleFinalsImageUpdate = (newImage: string | null) => {
    const patchedResults = { ...modifiedResults }
    patchedResults.finals.image = newImage
    setModifiedResults(patchedResults)
  }

  return (
    <ImageUploadCard
      titleText={"Image"}
      image={modifiedResults.finals.image}
      onChange={handleFinalsImageUpdate}
    />
  )
}
