import { type ComponentProps } from "react"

import { faDown, faUp, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "@mui/material"
import { GridActionsCellItem, DataGrid, type GridValidRowModel, type GridColumns } from "@mui/x-data-grid"
import { useTranslation } from "react-i18next"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props<R extends GridValidRowModel = any> extends ComponentProps<typeof DataGrid> {
  onTableDataPositionalChange: (modifiedRows: Array<R>) => void
}

export const ReorderableDataGrid = (props: Props) => {
  const { t } = useTranslation()

  const { onTableDataPositionalChange, columns: inputColumns, rows, ...rest } = props

  const columns: GridColumns<GridValidRowModel> = [
    ...inputColumns,
    {
      flex: 1,
      field: "actions",
      sortable: false,
      type: "actions",
      headerName: t("taiyoro:ljl.tableHeaders.actions"),
      minWidth: 65,
      getActions: (params) => [
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          disabled={rows.at(rows.length - 1)?.id === params.row.id}
          icon={
            <Tooltip
              title={t("common:actions.moveDown")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faDown}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => {
            const index = rows.findIndex((row) => row.id === params.row.id)
            const modifiedRows = [
              ...rows.slice(0, index), // Before the target
              rows[index + 1], // The target item
              rows[index], // The item it swaps with
              ...rows.slice(index + 2), // After the target
            ]
            onTableDataPositionalChange(modifiedRows)
          }}
          label={t("common:actions.moveDown")}
        />,
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          disabled={rows.at(0)?.id === params.row.id}
          icon={
            <Tooltip
              title={t("common:actions.moveUp")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faUp}
                style={{ fontSize: "1.2rem" }}
              />
            </Tooltip>
          }
          onClick={() => {
            const index = rows.findIndex((row) => row.id === params.row.id)
            const modifiedRows = [
              ...rows.slice(0, index - 1), // Before the target
              rows[index], // The target item
              rows[index - 1], // The item it swaps with
              ...rows.slice(index + 1), // After the target
            ]
            onTableDataPositionalChange(modifiedRows)
          }}
          label={t("common:actions.moveUp")}
        />,
        <GridActionsCellItem
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...({} as any)}
          icon={
            <Tooltip
              title={t("common:actions.delete")}
              enterDelay={0}
            >
              <FontAwesomeIcon
                icon={faTrash}
                style={{ fontSize: "1.2rem", color: "red" }}
              />
            </Tooltip>
          }
          onClick={() => {
            const modifiedRows = rows.filter((row) => row.id !== params.row.id)
            onTableDataPositionalChange(modifiedRows)
          }}
          label={t("common:actions.delete")}
        />,
      ],
    },
  ]

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      {...rest}
    />
  )
}
